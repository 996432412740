// var siteUrl = 'http://localhost:4000/';
var siteUrl = 'https://nodedemo.tjcg.in/';

const UrlHelper = {
    siteUrl: siteUrl,
    author: siteUrl + 'author',
    posts: siteUrl + 'posts',
    comments: siteUrl + 'comments',
    likes: siteUrl + 'likes',
    getProduct: siteUrl + 'getProduct',
    createProduct: siteUrl + 'createProduct',
    updateProduct: siteUrl + 'updateProduct',
    deleteProduct: siteUrl + 'deleteProduct',
    login: siteUrl + 'login',
    
}

export default UrlHelper;