import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from './Pages/Login/Login';
import Dashboard from './Pages/Dashboard/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import Product from './Pages/Product/Product';
import RouteWithSubRoutes from './RouteWithSubRoutes';
import axios from 'axios'
import UrlHelper from './Helper/UrlHelper';
import SnackBar from './Components/SnackBar';

const instance = axios.create({
  baseURL: UrlHelper.siteUrl,
  timeout: 1000,
  headers: {'X-Custom-Header': 'foobar'}
});


function App() {

  return (
    <>
      {/* <div className="box-shadow site-width" style={{ borderWidth: 1 }}> */}
      {/* <div className="pull-right">
          <SideDrawer />
        </div> */}
      <SnackBar />
      <Switch>
        <Route exact path="/" component={Login} />
        {/* <Route exact path="/app" component={Dashboard} /> */}
        {/* <Redirect to="/" /> */}
        <RouteWithSubRoutes
          path="/app"
          baseComponent={Dashboard}
          subRoutes={[
            '/app/',
            '/app/product'
          ]}
        />
      </Switch>
      {/* </div> */}
    </>
  );
}

export default App;
