const ConstantHelper = {
    authorListTitle: 'Authors List',
    posts: 'posts',
    comments: 'comments',
    likes: 'likes',
    noMoreDataTitle: 'No more data found',
    homeTitle: "Home",
    productTitle: "Product",
    logoutTitle: "Logout",
}

export default ConstantHelper;