import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { trackPromise } from 'react-promise-tracker';
import { setSnackBarErrorMessage, setSnackBarSuccessMessage } from '../../../Components/Store/SnackBarSliceData';
import loginService from './loginService'


export function loginAPI({ payload }) {
    return (dispatch) =>
        trackPromise(
            loginService.loginAPI({ payload })
                .then((response) => {
                    const { data } = response;
                    if(data?.status === false ) {
                        dispatch(setSnackBarErrorMessage({ message: data.msg }))
                    } else {
                        dispatch(setLoginSuccess(true))
                    }
                    // dispatch(getProductData({ id: null }))
                    // dispatch(setProductModal(false))
                    // dispatch(setSnackBarSuccessMessage({ message: "Product added successfully" }))

                    // dispatch(setAuthorDetails(data))
                    return data
                }).catch(error => {
                    dispatch(setSnackBarErrorMessage({ message: error }))
                    // dispatch(setAuthorDetails([]))
                })
        )
}

export function loginSuccessChange({ status }) {
    return (dispatch) =>
    dispatch(setLoginSuccess(status))
}



export const slice = createSlice({
    name: 'productPost',
    initialState: {
        productData: [],
        commentData: [],
        loginSuccess: false,
        authorDetailsData: [],
        postDetailsData: [],
        likeData: [],
        fetchStatus: false,
        productModal: false,
        editProductData: []
    },
    reducers: {
        setLoginSuccess: (state, action) => {
            state.loginSuccess = action.payload;
        },
    },
    extraReducers: {}
});

export const { setLoginSuccess } = slice.actions;

export default slice.reducer;
